.titleInfo {
  text-align: center;
  font-size: 32px;
  font-weight: bolder;
}

.BackdropStyle {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  
}
