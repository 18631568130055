* {
  box-sizing: border-box;
}

.titleHome {
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  font-size: 44px;
  color: rgb(14, 28, 52);
  background-image: linear-gradient(
    204.5deg,
    rgb(41, 103, 139) 0%,
    rgb(14, 28, 52) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@media screen and (max-width: 600px) {
  .titleHome {
    font-size: 35px;
  }
}

@media screen and (max-width: 420px) {
  .titleHomeMobile {
    margin-bottom: 20px;
  }
}
