.containerHome {
  width: fit-content;
  margin: auto;
  background-color: white;
  padding: 30px;
  text-align: center;
  transition: 0.5s;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.11);
  overflow: hidden;
}
.containerFullScreen {
  min-width: 75% !important;
}

.containerHome:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.11);
}

@media screen and (max-width: 420px) {
  .containerHome {
    padding: 25px 5px 20px;
    width: 100%;
    margin: 0;
  }
}
