.anyCertifiedHome {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    205.06deg,
    rgb(255, 223, 0) 0%,
    rgb(254, 77, 27) 48.15%,
    rgb(231, 1, 84) 100%
  );
  background-attachment: scroll;
  -webkit-background-clip: text;
  background-clip: text;
  background-color: rgba(0, 0, 0, 0);
}
