.titleResultCerified {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}
a {
  text-decoration: none;
}

.buttonCertified {
  font-style: normal;
  font-weight: 700;
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  color: rgb(0, 0, 0);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: 3px solid transparent;
  position: relative;
  transition: 0.3s;
}
.buttonCertified:hover {
  transform: translateY(-2px);
}

.buttonCertified::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(
    25.06deg,
    rgb(31, 149, 253) 0%,
    rgb(79, 232, 228) 100%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}


.buttonPending {
  opacity: 0.5;
}


