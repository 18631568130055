@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap");
* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: -1px;
}

body {
  background-color: #f9fbfc !important;
  padding-bottom: 10px;
}

.animationIcon {
  animation-name: animateIcon;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes animateIcon {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media screen and (max-width: 400px) {
  /* .container { */
  /* padding: 0 !important; */
  /* } */
}
