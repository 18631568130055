.inputHome {
  width: 100%;
  border: 1px solid rgb(109, 114, 120);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  min-width: 300px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 12px;
}

.buttonHome {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 30px;
  text-decoration: none;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  background: linear-gradient(
    205.06deg,
    rgb(255, 223, 0) 0%,
    rgb(254, 77, 27) 48.15%,
    rgb(231, 1, 84) 100%
  );
  border-radius: 6px;
  border: none;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.formHome {
  max-width: 400px;
}

.labelHome {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}


