.boxHeader {
  gap: 30px;
}

@media screen and (max-width: 380px) {
  .boxHeader {
    flex-wrap: wrap;
    gap: 10px !important;
  }
}
