.formSeach {
  max-width: 400px;
  margin-top: 31.5px;
  margin-left: 25px;
}

.buttonSeach {
  width: 100%;
  height: 50px;
  color: white !important;
  border: none;
  font-weight: bolder !important;
  font-size: 17px !important;
}

.buttonSeach:hover {
  background-color: #2a3035 !important;
}

.legendForm {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}

.inputSeach {
  width: 100%;
  border: 1px solid rgb(109, 114, 120);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  max-width: 400px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 12px;
}

@media screen and (max-width: 500px) {
  .formSeach {
    margin: 10px auto !important;
  }
}
