@import url('https://fonts.googleapis.com/css?family=Play');


.boxImage {
  max-width: 900px;
  flex-direction: column;
  margin: auto;
  padding: 30px;
  position: relative;
}


.buttonDownload {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 30px;
  text-decoration: none;
  display: flex;
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  border-radius: 6px;
  border: none;
  transition: all 0.2s ease 0s;
  background: linear-gradient(
    25.06deg,
    rgb(31, 149, 253) 0%,
    rgb(79, 232, 228) 100%
  );
  color: rgb(14, 28, 52);
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonDownload:hover {
  box-shadow: 0 18px 40px 0 rgba(79, 232, 228, 0.5);
}

.background_white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9fbfc;
}
