.buttonDesktop {
  color: white !important;
  display: flex !important;
  align-items: center !important;
  gap: 7px !important;
  height: 50px;
}

.buttonMobile {
  display: none !important;
}

@media screen and (max-width: 450px) {
  .buttonDesktop {
    display: none !important;
  }
  .buttonMobile {
    display: initial !important;
  }
}
