.HeaderHomer {
  background: linear-gradient(
    225deg,
    rgb(30, 79, 108) 0%,
    rgb(14, 28, 52) 100%
  );
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100vw;
}
.loginHome {
  font-weight: 500;
  color: #0e1c34;
  background: linear-gradient(29.83deg, #1f95fd 0%, #4fe8e4 100%);
  font-size: 25px;
}
.loginHome:hover {
  box-shadow: 0 18px 40px 0 rgba(79, 232, 228, 0.5);
}

@media screen and (max-width: 420px) {
  .loginHome {
    margin-right: 5px;
  }
  .logo {
    margin-left: 5px;
  }
}
