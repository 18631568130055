.titleForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-bottom: 2.5rem;
  padding-bottom: 3px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.123);
  border-radius: 8px;
}

@media screen and (max-width: 350px) {
  .iconMobile {
    display: none;
  }
}
