.containerResult {
  padding: 8px 16px;
  /* padding: 30px 40px; */
  background-color: #ffffff;
  border-radius: 8px;
  /* border: 1px solid #ced4da; */
  /* max-width: 555px; */
  max-height: calc(75vh - 32px);
  /* max-height: 70vh; */
  overflow: auto;
}

.titleResult {
  font-weight: bolder !important;
  text-align: center;
}

.legendForm {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
  margin-top: 15px;
}

.buttonSeach {
  width: 100%;
  font-weight: bolder;
  padding: 8px;
}
