@import url(https://fonts.googleapis.com/css?family=Play);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap);
.main_boxHeader__3sR_u {
  grid-gap: 30px;
  gap: 30px;
}

@media screen and (max-width: 380px) {
  .main_boxHeader__3sR_u {
    flex-wrap: wrap;
    grid-gap: 10px !important;
    gap: 10px !important;
  }
}

.AdmButton_buttonDesktop__2sxG7 {
  color: white !important;
  display: flex !important;
  align-items: center !important;
  grid-gap: 7px !important;
  gap: 7px !important;
  height: 50px;
}

.AdmButton_buttonMobile__1Ghhl {
  display: none !important;
}

@media screen and (max-width: 450px) {
  .AdmButton_buttonDesktop__2sxG7 {
    display: none !important;
  }
  .AdmButton_buttonMobile__1Ghhl {
    display: initial !important;
  }
}

.GenerateCertificado_titleInfo__3rzjq {
  text-align: center;
  font-size: 32px;
  font-weight: bolder;
}

.GenerateCertificado_BackdropStyle__14AZF {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  
}

* {
  box-sizing: border-box;
}

.titleHome_titleHome__3BeVt {
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  font-size: 44px;
  color: rgb(14, 28, 52);
  background-image: linear-gradient(
    204.5deg,
    rgb(41, 103, 139) 0%,
    rgb(14, 28, 52) 100%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@media screen and (max-width: 600px) {
  .titleHome_titleHome__3BeVt {
    font-size: 35px;
  }
}

@media screen and (max-width: 420px) {
  .titleHome_titleHomeMobile__3_MBS {
    margin-bottom: 20px;
  }
}

.anyCertified_anyCertifiedHome__1__vp {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    205.06deg,
    rgb(255, 223, 0) 0%,
    rgb(254, 77, 27) 48.15%,
    rgb(231, 1, 84) 100%
  );
  background-attachment: scroll;
  -webkit-background-clip: text;
  background-clip: text;
  background-color: rgba(0, 0, 0, 0);
}

.formHome_inputHome__10SpR {
  width: 100%;
  border: 1px solid rgb(109, 114, 120);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  min-width: 300px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 12px;
}

.formHome_buttonHome__WiY50 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 30px;
  text-decoration: none;
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  background: linear-gradient(
    205.06deg,
    rgb(255, 223, 0) 0%,
    rgb(254, 77, 27) 48.15%,
    rgb(231, 1, 84) 100%
  );
  border-radius: 6px;
  border: none;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.formHome_formHome__UR_gk {
  max-width: 400px;
}

.formHome_labelHome__3Qz_h {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}



.result_titleResultCerified__2aSCD {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}
a {
  text-decoration: none;
}

.result_buttonCertified__2Aj8z {
  font-style: normal;
  font-weight: 700;
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  color: rgb(0, 0, 0);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: 3px solid transparent;
  position: relative;
  transition: 0.3s;
}
.result_buttonCertified__2Aj8z:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.result_buttonCertified__2Aj8z::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(
    25.06deg,
    rgb(31, 149, 253) 0%,
    rgb(79, 232, 228) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}


.result_buttonPending__NEXQL {
  opacity: 0.5;
}



.titleForm_titleForm__1J-vV {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
  margin-bottom: 2.5rem;
  padding-bottom: 3px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.123);
  border-radius: 8px;
}

@media screen and (max-width: 350px) {
  .titleForm_iconMobile__it77r {
    display: none;
  }
}

.boxContainer_containerHome__ui_kJ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  background-color: white;
  padding: 30px;
  text-align: center;
  transition: 0.5s;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.11);
  overflow: hidden;
}
.boxContainer_containerFullScreen__10Kxl {
  min-width: 75% !important;
}

.boxContainer_containerHome__ui_kJ:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.11);
}

@media screen and (max-width: 420px) {
  .boxContainer_containerHome__ui_kJ {
    padding: 25px 5px 20px;
    width: 100%;
    margin: 0;
  }
}

.header_HeaderHomer__YwTwJ {
  background: linear-gradient(
    225deg,
    rgb(30, 79, 108) 0%,
    rgb(14, 28, 52) 100%
  );
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100vw;
}
.header_loginHome__2ELC1 {
  font-weight: 500;
  color: #0e1c34;
  background: linear-gradient(29.83deg, #1f95fd 0%, #4fe8e4 100%);
  font-size: 25px;
}
.header_loginHome__2ELC1:hover {
  box-shadow: 0 18px 40px 0 rgba(79, 232, 228, 0.5);
}

@media screen and (max-width: 420px) {
  .header_loginHome__2ELC1 {
    margin-right: 5px;
  }
  .header_logo__3jaeZ {
    margin-left: 5px;
  }
}

.BoxCertificateImage_boxImage__1reuH {
  max-width: 900px;
  flex-direction: column;
  margin: auto;
  padding: 30px;
  position: relative;
}


.BoxCertificateImage_buttonDownload__1Q8qw {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 30px;
  text-decoration: none;
  display: flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  border-radius: 6px;
  border: none;
  transition: all 0.2s ease 0s;
  background: linear-gradient(
    25.06deg,
    rgb(31, 149, 253) 0%,
    rgb(79, 232, 228) 100%
  );
  color: rgb(14, 28, 52);
  margin-top: 20px;
  margin-bottom: 20px;
}

.BoxCertificateImage_buttonDownload__1Q8qw:hover {
  box-shadow: 0 18px 40px 0 rgba(79, 232, 228, 0.5);
}

.BoxCertificateImage_background_white__2Ah8p {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9fbfc;
}

.boxResult_containerResult__1Qo_I {
  padding: 8px 16px;
  /* padding: 30px 40px; */
  background-color: #ffffff;
  border-radius: 8px;
  /* border: 1px solid #ced4da; */
  /* max-width: 555px; */
  max-height: calc(75vh - 32px);
  /* max-height: 70vh; */
  overflow: auto;
}

.boxResult_titleResult__hHgzk {
  font-weight: bolder !important;
  text-align: center;
}

.boxResult_legendForm__2bBmv {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
  margin-top: 15px;
}

.boxResult_buttonSeach__34h7f {
  width: 100%;
  font-weight: bolder;
  padding: 8px;
}

.form_formSeach__3aDQH {
  max-width: 400px;
  margin-top: 31.5px;
  margin-left: 25px;
}

.form_buttonSeach__mG5z3 {
  width: 100%;
  height: 50px;
  color: white !important;
  border: none;
  font-weight: bolder !important;
  font-size: 17px !important;
}

.form_buttonSeach__mG5z3:hover {
  background-color: #2a3035 !important;
}

.form_legendForm__1N1TY {
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 25px;
  color: rgb(14, 28, 52);
  text-transform: uppercase;
}

.form_inputSeach__3vu-D {
  width: 100%;
  border: 1px solid rgb(109, 114, 120);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  max-width: 400px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 12px;
}

@media screen and (max-width: 500px) {
  .form_formSeach__3aDQH {
    margin: 10px auto !important;
  }
}

* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: -1px;
}

body {
  background-color: #f9fbfc !important;
  padding-bottom: 10px;
}

.animationIcon {
  -webkit-animation-name: animateIcon;
          animation-name: animateIcon;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes animateIcon {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-5px);
            transform: translatey(-5px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes animateIcon {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-5px);
            transform: translatey(-5px);
  }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@media screen and (max-width: 400px) {
  /* .container { */
  /* padding: 0 !important; */
  /* } */
}

